<template>
  <div class="">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="relative bg-white">
        <h2 class="sr-only">Contact us</h2>
        <div class="grid grid gap-10 md:grid-cols-10">
          <div class="mb-10 md:mb-0 md:col-span-5   rounded-xl overflow-hidden aspect-w-1 aspect-h-1 ">
            <img src="@/assets/images/smile.jpg" alt="Ted Michaels giving a presentation" class="w-full h-full object-cover object-center">
          </div>            
        <div class="md:col-span-5 flex">
          <div class="my-auto">
          <h2 class="mb-0">Get In Touch</h2><div class="w-20 h-1 my-5 bg-accent-light"></div>
          <div class="mt-3">
            <p class="text-lg ">Ted Michaels, Corus Radio Hamilton 2021 Hall of Fame Inductee, Health and Wellness advocate, Public speaker, charity fundraiser.</p>
            
          </div>
          <div class="mt-9">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: outline/mail -->
                <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              </div>
              <div class="ml-3 text-base text-gray-500">
                <p><a class="border-b border-accent hover:text-accent" href="mailto:TedMichaels1954@hotmail.com?subject=Website Inquiry">TedMichaels1954@outlook.com</a></p>
              </div>
            </div>
            
            <div class="mt-4 flex space-x-3 items-center">
             <a class="transition hover:opacity-75" target="_blank" href="https://twitter.com/mrtedmichaels">
          <img src="@/assets/twitter.svg" alt="" />
        </a>
        <a class="transition hover:opacity-75" title="Visit Facebook page" href="https://www.facebook.com/ted.michaels.1" target="_blank">
              <img src="@/assets/facebook.svg" alt="" />
        </a>
        <a class="transition hover:opacity-75" title="Visit LinkedIn page" href="https://www.linkedin.com/in/ted-michaels-2b2112/"  target="_blank">
          <img src="@/assets/linkedin.svg" alt="" />
        </a>
        <a class="transition hover:opacity-75" target="_blank"  href="https://www.instagram.com/tedmichaels1954/">
          <img src="@/assets/instagram.svg" alt="" />
        </a>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from "@heroicons/vue/outline";
export default {
  name: "Contact Section",
  components: {
    MailIcon,
    PhoneIcon,
  },
};
</script>
